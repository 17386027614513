import "./nav.css";
import { Link } from "react-scroll";

function Nav() {

  return (
    <div className="nav section-container">
      <div className="logo">
        <div className="logo-text">NEHA BISHT</div>
      </div>
      <div className="nav-menu">
        <div className="menu-item">
          <Link activeClass="active" to="about" spy={true} smooth={true}>
            About
          </Link>
        </div>
        <div className="menu-item">
          <Link activeClass="active" to="work" spy={true} smooth={true}>
            Work
          </Link>
        </div>
        <div className="menu-item cta">
          <Link activeClass="active" to="contact" spy={true} smooth={true}>
            Contact
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Nav;
