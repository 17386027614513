import "./about.css";

function About() {
  return (
    <div className="about-container" id="about">
      <div className="about-section section-container">
        <div className="about-content">
          <div className="about-heading">Is your business scalable?</div>
          <div className="about-description">
            Just like you, I get frustated doing the same tasks over and over
            because it stops our business from growing. That's why I'm all about
            using tech to make things easier for businesses.
            <br></br>
            <br></br>
            <span className="font-medium">
              {" "}
              Hello, I'm Neha Bisht and I build Tech solutions for products.
            </span>
                      <br></br>
                      <br></br>
            From infrastructure to green energy, healthcare to automobiles, and
            business intelligence, I've constructed tech solutions across
            various sectors.<br></br>
            <br></br> I help business owners like you escape the daily grind
            using tech so that you can focus on what matters the most. I
            throughly review the business processes to develop a custom solution
            that fits your needs.
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
