import { useState } from "react";
import logo from "../../mine.png";
import linkedin from "../../linkedin.png";
import "./contact.css";
import { PopupModal } from "react-calendly";

function Contact() {
  const url = "https://calendly.com/bishtn1010/one-on-one-with-me";
  const linkedinUrl = "https://www.linkedin.com/in/neha-bisht-8a6297287/";
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);
  return (
    <div className="contact-container" id="contact">
      <div className="section-container">
        <div className="content">
          <div className="flex gap-4 items-center">
            <span>
              <img src={logo} className="w-14" alt="logo" />
            </span>
            <span>
              Let's Work <br></br>
            </span>
          </div>
          <div className="px-10 py-2">Together</div>
        </div>
        <div className="flex items-center">
          <div className="horz-line"></div>
          <div className="book-call">
            <button onClick={() => setIsCalendlyOpen(true)}>Book a call</button>
          </div>
        </div>
        <div className="footer-row" id="calendly">
          <div className="email">bishtn1010@gmail.com</div>
          <a href={linkedinUrl} target="_blank" rel="noopener noreferrer">
            <img src={linkedin} className="linkedin" alt="linkedin" />
          </a>
        </div>
        <PopupModal
          url={url}
          onModalClose={() => setIsCalendlyOpen(false)}
          open={isCalendlyOpen}
          rootElement={document.getElementById("root")}
        />
      </div>
    </div>
  );
}

export default Contact;
