import "./App.css";
import Hero from "./components/hero/hero";
import Nav from "./components/nav/nav";
import About from "./components/about/about";
import Contact from "./components/contact/contact";
import WorkHorz from "./components/work-horz/work-horz";

function App() {
  return (
    <div className="App">
      <Nav />
      <Hero />
      <About />
      <WorkHorz />
      <Contact />

    </div>
  );
}

export default App;
