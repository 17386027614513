import logo from "../../mine.png";
import "./hero.css";
import { useState } from "react";
import { PopupModal } from "react-calendly";

function Hero() {
  const url = "https://calendly.com/bishtn1010/one-on-one-with-me";

  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);
  return (
    <div className="hero-container section-container">
      <div className="hero-content">
        <div className="heading">
          Make your business run on
          <span className="text-blue"> AUTOPILOT</span>
        </div>
        <div className="heading-description">
          Helping businesses stay ahead by easily adapting to technological
          advancements and industry changes.
        </div>
        <div>
          <button className="hero-button" onClick={() => setIsCalendlyOpen(true)}>Book a call</button>
        </div>
      </div>
      <div className="hero-image">
        <img src={logo} className="my-image" alt="logo" />
      </div>
      <PopupModal
        url={url}
        onModalClose={() => setIsCalendlyOpen(false)}
        open={isCalendlyOpen}
        rootElement={document.getElementById("root")}
      />
    </div>
  );
}

export default Hero;
