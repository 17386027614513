import { useState, useRef } from "react";
import p3 from "../../images/p3.png";
import health from "../../images/health.png";
import igenie from "../../images/igenie.png";
import sgsolar from "../../images/sgsolar.png";
import "./work-horz.css";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/mousewheel";

function WorkHorz() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMove, setIsMove] = useState(true);

  const slides = [
    {
      src: p3,
      title: "PumPumPum",
      subTitle: "Car Leasing Service platform",
      content:
        "PumPumPum is India's first monthly car subscription service offering certified pre-owned cars for personal and business use. ",
      subContent:
        "It's an online platform for sellers to list cars, undergo inspection, and for buyers to browse based on different factors. The platform offers a complete solution for vehicle preparation, logistics, and paperwork.",
      link: "https://pumpumpum.com/",
    },
    {
      src: health,
      title: "Healthtrip",

      subTitle: "Health Travel Platform",
      content:
        "Healthtrip is one of Asia's largest Medical Travel company, providing healthcare solution to patients, across the world, booking appointments with hospitals, flights, hotels, tourism, and providing loans and insurance, in a seamless experience.",
      subContent:
        "The project focused on creating a unified platform for appointments, travel arrangements, accommodations, and financial services.",
      link: "https://www.healthtrip.com/",
    },
    {
      src: igenie,
      title: "Igenie-AI",
      subTitle: "AI-powered Business Intelligence Platform",
      content:
        "A platform that turn businesses data from e-commerce platforms into actionable insights and enhance decision-making using AI-powered tools",
      subContent:
        "It constructs dashboards to assess product performance for brands and their competitors. Through the development of advanced algorithms, the project aims to derive meaningful insights that contribute to enhanced decision-making for businesses.",
      link: "https://www.i-genie.ai/",
    },
    {
      src: sgsolar,
      title: "SG Solar",
      subTitle: "Solar Energy Solutions for Homes and Businesses",
      content:
        "SGSolar provides energy independence and financial savings to homeowners and businesses by providing technical and finance solutions of the solar power system",
      subContent:
        "The development focused on creating a web platform managing  the entire solar system lifecycle, from installation and maintenance. A key feature included user-friendly onboarding, enabling customers to outline their roof area using Google Maps.",
      link: "https://sgsolarhome.com/",
    },
    {
      src: sgsolar,
      title: "Bizisell",
      subTitle: "A Sales Service Platform",
      content:
        "A Sales Service Platform to provide services to businesses such as sales outsourcing tasks, customer acquisition, and retention operations",
      subContent:
        "The development created a Sales Service Platform with a task management system for sales-related activities. It integrated a payout system based on task completion, ensuring efficient and transparent compensation for users.",
      link: "https://www.bizisell.com/",
    },
  ];

  const swiperRef = useRef();

  const handleSlideChange = (i) => {
    console.log(i);
    console.log(swiperRef);
    setCurrentSlide(i);
    setIsMove(true);
    setTimeout(() => {
      setIsMove(false);
    }, 500);
  };

  const onSwiperStart = (swiper) => {
    swiperRef.current = swiper;

    setTimeout(() => {
      setIsMove(false);
    }, 500);
  };

  return (
    <div className="section-container" id="work">
      <div className="block lg:hidden section-headline">Portfolio</div>

      <div className="work-container-horz ">
        <div className="left-horz ">
          <div className="section-headline hidden lg:block ">Portfolio</div>
          <div className={isMove ? "work-content isMove" : "work-content"}>
            <div className="slide-title">{slides[currentSlide].title}</div>
            <div className="slide-subtitle">
              {slides[currentSlide].subTitle}
            </div>
            <div className="slide-content">
              <span>{slides[currentSlide].content}</span>
              <br></br>
              <br></br>
              <span>{slides[currentSlide].subContent}</span>
            </div>
          </div>
        </div>
        <div className="right-horz">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={40}
            slidesPerView={1.0}
            navigation={true}
            pagination={{ clickable: true }}
            onSlideChange={(i) => handleSlideChange(i.activeIndex)}
            onSwiper={(swiper) => onSwiperStart(swiper)}
          >
            {slides &&
              slides.map((i, index) => (
                <SwiperSlide className="slide-main-horz">
                  <img src={i.src} className="image-main-horz" alt="work" />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default WorkHorz;
